<template>
  <div>
    <SubHeader />
    <div id="aboutBand">
      <div class="docContent">
        <h1>Contact</h1>
        <p>
          <b>For Booking:</b><br>
          Laura Lee Crandall<br>
          midnightrodeomusic@gmail.com, 816-419-8526</p>
        <div style="clear: both; margin-bottom: 50px"></div>
      </div>
    </div>
    <div style="float: both;"/>
  </div>
</template>

<script>
import SubHeader from '../components/SubHeader.vue'

export default {
  components: {
    SubHeader
  }
}
</script>

<style scoped>
.docContent {
  text-align: left;
  max-width: 980px;
  width: 90%;
  margin: 20px auto;
}

.docImage {
  width: 350px;
  float: right;
  margin: 10px 20px;
}

.left {
  float: left;
}
</style>
