<template>
  <div id="app">
    <Header />
    <div id="bodyWrapper">
      <router-view/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed&display=swap');

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
}

html, body {
  background-color: black;
  color: white;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold !important;
  text-align: left;
  padding: 10px 30px;
  text-transform: uppercase;
  font-size: 1.75em !important;
}

.table-striped tbody {
  background-color: #BAAAA4;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.35) !important;
}

table tr, table tr td {
  border-style: none !important;
  color: white;
}

#homeShowsTable tbody tr {
        font-family: 'Roboto Condensed', sans-serif;
        // font-weight: bold !important;
        text-transform: uppercase;
        font-size: 1.5em !important;
    }

@media (max-width: 830px) {
  #homeShowsTable tbody tr {
    font-size: 1em !important;
  }
}

@media (max-width: 600px) {
  #app {
    font-size: 12px;
  }
}

#app a {
    color: #BAAAA4;
  }
</style>
